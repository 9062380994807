import type { SiteInfoFragment } from '#graphql-operations'

export default async function useCurrentStyles() {
  const data = await useInitData()

  type ColorProperty = {
    name: string
    value: string
  }

  const colorStyles = computed(() => {
    const colorPalettes: (keyof SiteInfoFragment)[] = ['primaryColorPalette']

    // Iterate over colors and create CSS variables
    return colorPalettes.reduce((acc, color) => {
      const colorValue = data.value?.siteInfo?.[color] as [ColorProperty]
      if (!colorValue) {
        return acc
      }

      return (
        acc +
        colorValue.reduce((innerAcc, { name, value }) => {
          return `${innerAcc}${name}: ${value}; `
        }, '')
      )
    }, '')
  })

  const singleColors = computed(() => {
    const colors: (keyof SiteInfoFragment)[] = [
      'buttonText',
      'headerFooterBackground',
      'headerFooterText',
    ]

    // Iterate over colors and create CSS variables
    return colors.reduce((acc, color) => {
      const colorValue = data.value?.siteInfo?.[color] as [string]
      if (!colorValue) {
        return acc
      }

      // Lowercase and replace camelCase with kebab-case
      const name = color.replace(/([A-Z])/g, '-$1').toLowerCase()

      return acc + `--color-${name}: ${colorValue}; `
    }, '')
  })

  return colorStyles.value + singleColors.value
}
